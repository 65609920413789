import React, { useEffect, useState } from "react";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  clearPolicyErrors,
  getAllPolicies,
} from "../redux/actions/policyAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import usePaginate from "../hooks/usePaginate";
import "../styles/tableDataStyle.scss";
// import { FaRegEdit } from "react-icons/fa";
import NoData from "../components/NoData";
import CustomForm from "../components/CustomForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AllPolicies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchString, setSearchString] = useState("");
  const [allData, setAllData] = useState([]);

  const {
    loading,
    data: allPolicies,
    error,
  } = useSelector((state) => state.getAllPoliciesReducer);

  useEffect(() => {
    dispatch(getAllPolicies());
  }, [dispatch]);

  console.log("allData", allData);

  useEffect(() => {
    if (allPolicies?.success) {
      setAllData(allPolicies?.policies);
    }
    if (error) {
      toast.error(error);
      dispatch(clearPolicyErrors());
    }
  }, [error, dispatch, allPolicies?.success, allPolicies?.policies]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(allData);
  // Pagination Hook Ends

  // SEARCH POLICIES START
  useEffect(() => {
    if (startDate && endDate && searchString) {
      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0);

      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);

      let filtered = allData.filter((item) => {
        console.log("item", item);

        const createdAt = new Date(item.createdAt);
        return createdAt >= start && createdAt <= end;
      });

      filtered = filtered?.filter((policy) =>
        policy?.policyRefferer?.panNumber
          ?.toLocaleLowerCase()
          .includes(searchString?.toLocaleLowerCase())
      );

      console.log("filtered", filtered);

      setAllData(filtered);
    }else {
      setAllData(allPolicies?.policies);
    }
  }, [allData, allPolicies?.policies, endDate, searchString, startDate]);

  return loading ? (
    <Loader />
  ) : (
    <div className="AddPolicy_main">
      <SidebarLayout>
        <div className="AddPolicy_content">
          <PageTitle title="All Policies" />
          <div className="filterPolicy">
            <CustomForm>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="policyType">
                      Pan Card
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="searchString"
                      placeholder="Policy Type"
                      name="searchString"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="policyType">
                      Date Range
                    </label>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      withPortal
                      placeholderText="Select a Date Range"
                    />
                  </div>
                </div>
                {/* <button
                  style={{
                    margin: "20px auto",
                    display: "block",
                    width: "fit-content",
                  }}
                  className="btn btn-warning searchButton"
                  onClick={handleSearchPolicies}
                >
                  Search
                </button> */}
              </div>
            </CustomForm>
            <button
              className="btn btn-primary ms-auto d-block mt-4"
              onClick={() => navigate("/add-policy")}
            >
              Add New Policy
            </button>
          </div>
          <div className="agents_table mt-4">
            {!allPolicies?.policies.length ? (
              <NoData content="Policies not found" />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table  table-hover table-bordered customTable">
                    <thead>
                      <tr>
                        <th scope="col">Policy No</th>
                        <th scope="col">Policy Holder</th>
                        <th scope="col">Insurer (Company)</th>
                        <th scope="col">Valid Till (Date)</th>
                        <th scope="col">Premium</th>
                        <th scope="col">Associate's Points</th>
                        <th scope="col">Referred By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((itm, itmIndex) => (
                        <tr key={itmIndex}>
                          <td>
                            {itm?.policyNumber ? itm?.policyNumber : "null"}
                          </td>
                          <td>{itm?.policyHolder}</td>
                          <td>{itm?.insurerCompany?.companyName}</td>
                          <td>
                            {moment(itm?.policyStartDate).format("DD-MM-YYYY")}{" "}
                            - {moment(itm?.policyEndDate).format("DD-MM-YYYY")}
                          </td>
                          <td>₹ {itm?.premium}</td>
                          <td>{itm?.policyReffererCommission}</td>
                          <td>
                            {itm?.policyRefferer?._id
                              ? itm?.policyRefferer?.fullName +
                                `(${itm?.policyRefferer?.panNumber})`
                              : "null"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default AllPolicies;
